<template>
 <div style='margin: 0pt; width:100%;' ref='breakWrapper'>
	    <!-- HEADER -->
	    <div style='float: top; width: 100%;' class='higher'>
	        
		    <div style='float: top;' class="todrag higher" 
		         @dragstart.ctrl="startDragBreak($event, identifier, 'copy')"
		         @dragstart.exact="startDragBreak($event, identifier, 'move')" draggable
		       >
			    <div v-for="(col,idx) in selectedColumns" :key="'colh-'+idx" class='floatLeft' 
			         :style='getColumnStyle(col)'
			        @click='setOrderBy(col)'
			        @drop.exact="onDropSpot($event, idx, identifier)"
			        @drop.ctrl="onDropSpot($event, idx, identifier, 'copy')"
			        @contextmenu='showColumnSelect($event)'
			        @dragover.prevent
					@dragenter.prevent>
					
			       <div :class="((spots2Show && spots2Show.length)?'header':'headerEmpty')+ ' higher'" :style='getSpotDetailStyle(col)'>
			       		<div v-show="!col.buttons"> {{ col.name }}<sub>{{getOrderBy(col)}}</sub></div>
			       	</div>
			    </div>
			   
		    </div>  <!-- float: top -->   
		</div> <!-- HEADER -->
		<div style='width: 100%;'>
	    	<div style='float: top; width: 100%;'>
	    	    <div v-for="(spot,idx) in spots2Show" :key="+dayId+'-'+idx"  :id="dayId+'-'+spot.id" 
	    	         class="todrag spotLine" 
	    	       @dragover.prevent
				   @dragenter.prevent
				   @mouseenter="hoverLine(dayId+'-'+spot.id)"
			       @mouseleave="leaveLine(dayId+'-'+spot.id)"
                   @drop.exact="onDropSpot($event, idx, identifier)"
                   @drop.ctrl="onDropSpot($event, idx, identifier, 'copy')"
                   @dragstart.ctrl="startDragSpot($event, spot, idx, identifier, 'copy')"
	    	       @dragstart.exact="startDragSpot($event, spot, idx, identifier, 'move')" draggable
	    	    >
	    	         
	    	        <div v-for="(col,idx) in selectedColumns" :key="'col-'+idx" class='floatLeft ' 
	    	               @contextmenu='spotRClicked($event, spot)' :style='getColumnStyle(col,spot)'>
				        <div v-if="col.buttons" class='spotDetail'>
				       		<button @click='deleteSpot(spot)' class='iButton'>
				       		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#aaa" class="bi bi-trash" viewBox="0 0 16 16">
							  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
							  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
							</svg>
				       		</button>
				       	</div>
				       <div v-else class='spotDetail transitionFast' :style='getSpotDetailStyle(col, spot, idx)' 
				            @click='mark(spot, idx)'>
				       		{{ print(spot, col) }}
				       	</div>
				    </div> 

	    	    </div>
	    		
	    	</div> 
    	</div>  <!-- TABLE -->
    	
		<ColumnSelector v-show='showColumnSelector' 
	        :availableColumns=availableColumns
	        :selected=selected
	 
			@selectedColumns=setSelectedColumns
	        @close="showColumnSelector=false">
        
    	</ColumnSelector>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, bngAPI, setReload, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import BreakView from '@/components/BreakView';
import ColumnSelector from '@/components/ColumnSelector';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW Spot-List View',
  components : {
   ColumnSelector
  },
  props: {
    spots: Array,
    grid: Object,
    dayId: String,
    identifier: String,
    time: Number,
    updatedSpots: Array,
    name: String,
    update: Number,
    fontSize: {type: Number, default: 9},
    user: {},
    selectedIN: String,
    display: Boolean
  },
  data () {
    return {
        gridAds: {},
        filteredSpots: [],
        filteredByDates: 0,
		width: 100,
        height: 100,
        mainHeight: 100,
        
        showColumnSelector: false,
        weekdayLabel: [],
        withAds: true,
        starttime: 0,
        dayStartAt: 0,
        controlsRight: false,

        getAppStyle,
        //
        spots2Show: [],
        //
      
        loader: {},
        loadingActive: false,
        //
        weekdayArray: [],
        selectedRecord: {},
        selectedIndex: 0,
        showBreakView: false,
        showEditor: false,
        breakReference: {},
        widthCalculated: 100,
        avColumnsVisible: 0,
        orderBy: [],
        marked: [],
        availableColumns: [
	        {name: "Pos", show: "position", w: 3, align: 'C' },
	        {name: "ID", show: "id", w: 10, align: 'C' },
			{name: "BreakId", show: "breakId", w: 10, align: 'C' },
			{name: "BreakCode", show: "breakCode", w: 10, align: 'C' },
	        {name: "Date", show: "date", w: 12, align: 'C' },
	        {name: "To", show: "placementTo", w: 12, align: 'C' },
	        {name: "Date", show: "date", w: 12, align: 'C' },
	        {name: "WeekDay", show: "weekday", weekday: true, w: 9, align: 'C' },
	        {name: "XC", show: "exchangeable", boolean: true, w: 2, align: 'C' },
	        {name: "Program", show: "gridName", w: 30, align: 'C' },
	        {name: "Rerun", show: "gridIsRerun", boolean:true, w: 5, align: 'C' },
	        {name: "Grid-Time", show: "gridTime", time:true, w: 12, align: 'C' },
	        {name: "TimeSlot", show: "gridTimeSlot", w: 8, align: 'C'},
	        {name: "Time", show: "time", time:true, w: 8, align: 'C' },
	        {name: "Break", show: "breakTypeShort", w: 6, align: 'C' },
	        {name: "BusType", show: "businessType", w: 10, align: 'C' },
	        {name: "Break#", show: "breakNo", w: 5, align: 'C' }, 
	        {name: "Channel", show: "channelShort", w: 10, align: 'L' },
	        {name: "PG", show: "pricegroup", w: 5, align: 'C' },
	        {name: "mainId", show: "mainSpotId", w: 8, align: 'C' },
	        {name: "Placement", show: "placementName", w: 15, align: 'L' },
	        {name: "Product", show: "productName", w: 15, align: 'L' },
	        {name: "PosReq", show: "positionRequest", w: 5, align: 'L' },
	        {name: "Client", show: "clientName", w: 15, align: 'L' },
	        {name: "Agency", show: "agencyName", w: 15, align: 'L' },
	        {name: "Priority", show: "priority", w: 5, align: 'C' },
	        {name: "ProductFamily", show: "productFamily", w: 15, align: 'L' },
	        {name: "ProductGroup", show: "productGroup", w: 15, align: 'L' },
	        {name: "Dur", show: "duration", w: 5, align: 'C' },
	        {name: "Copy", show: "copyName", w: 20, align: 'L' },
	        {name: "HouseNumber", show: "copyHouseNumber", w: 15, align: 'C' },
	        {name: "Code", show: "copyCode", w: 15, align: 'C' },
	        {name: "Type", show: "placementType", w: 8, align: 'C' },
	        {name: "Gross", show: "gross5", w: 10, align: 'R' },
	        {name: "Gross"+sessionStorage.hCurrency, show: "grossHC", w: 10, align: 'R' },
	        {name: "Currency", show: "currency", w: 10, align: 'R' },
	        {name: "Net", show: "net", w: 10, align: 'R' },
	        {name: "Issue", show: "issue",  string:true, w: 5, align: 'C' },
	        {name: "Sev", show: "severity",  string:true, w: 3, align: 'C' },
	        {name: "IssId", show: "issueId", w: 10, align: 'C' },
	        {name: "Fixed", show: "gross4", w: 10, align: 'R' },
	        {name: "Ratecard", show: "gross3", w: 10, align: 'R' },
	        {name: "Ratecard 30", show: "gross1", w: 10, align: 'R' },
	        {name: "Status", show: "statusName", w: 10, align: 'C' },
	        {name: "RateType", show: "rateType", w: 4, align: 'C' },
			{name: "Created", show: "created", w: 10, align: 'C' },
	        {name: "Valid", show: "statusValid", boolean: true, w: 10, align: 'C' },
	       
	        {name: "InPlaylist", show: "statusInPlaylist", boolean: true, w: 10, align: 'C' },
	       
	        {name: "Invalid", show: "invalid", boolean: true, w: 5, align: 'C' },
	        {name: "Reason", show: "reason4Invalid", w: 20, align: 'C' },
	        {name: "(Action)", show: "-", w: 5, buttons: true}
        
        ],
        selectedDefault: ["Pos","Date","Break#", "Break", "Product", "ProductFamily", "Dur", "Copy", "Gross", "Net", "Status", "(Action)"],
        selected: [],
        selectedColumns: [
	        
        ]
    }
  },
  /////
 
  /////
  methods: {
     getAvColumnClasses()  { if(!this.avColumnsVisible) { return "invisible transition" } else { return "choice normal floatLeft button transition"}},
     myLocale() { return myLocale();},
     getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex;"
     },
     setSelectedColumns( sc ) { this.selectedColumns = sc; this.calcWidths( true ) },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     close() { this.breakReference = null; this.$emit("close"); },
     hasTimezone() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     identity(x) { return x },
     setAvColumnsVisible( val) { this.avColumnsVisible = val},
     spotRClicked(event, spot)
     {
        event.preventDefault();
        let ctx = {name: this.name, spot: spot, grid: this.grid}
        this.$emit("contextmenu", event, ctx);
     },
     hoverLine(line)
     {
    	$('#'+line).children().addClass('reqLineBG');
     },
     leaveLine(line)
     {
    	$('#'+line).children().removeClass('reqLineBG');
     },
     showColumnSelect(event)
     {
        event.preventDefault();
     	this.showColumnSelector=true;
     },
     openBreak( event, spot)
     {
        this.$refs.menu.close();
     	this.selectedRef = this.getRawKey(spot);
     	this.showBreakView = true;
     },
     getSpotDetailStyle(col, spot, idx)
     {
     	let textAlign = 'justify-content: center;';
        if ( col.align == 'L' )
        {
        	textAlign = 'justify-content: left;';
        }
        else if ( col.align == 'R' )
        {
        	textAlign = 'justify-content: right;';
        }
        if ( this.loadingActive )
        {
        	return textAlign+"color: #aaa; font-size:"+this.fontSize+"px";
        }
        if ( this.identifier === 'Clipboard' && spot && spot.invalid )
        {
        	return textAlign+"color: #aaa; font-size:"+this.fontSize+"px";
        }
        if ( idx === 0 && spot && spot.issueColour) 
        {
        	return textAlign+"font-size:"+this.fontSize+"px; color: #000; background-color: "+spot.issueColour+" !important;";
        }
        if ( !spot )
        {
        	return textAlign+"color: #000; font-size:"+this.fontSize+"px;";
        }
     	return textAlign+"font-size:"+this.fontSize+"px;";
     },
     getRawKey(spot)
     {
    	return spot.gridId+":"+spot.mediaId+":"+spot.breakNo+":"+spot.breakTypeId+":"+spot.date;
     },
     closeBreakView() {
    	this.showBreakView = false;
     },
     removeCopy( spot )
     {
     	this.$emit("removeCopy", this.getActiveSpots(spot));
     },
     markXChangeable( spot, flag)
     {
        //console.log("SETPRICE("+price+")");
     	this.$emit("markXChangeable", this.getActiveSpots(spot), flag);
     },
	 setPositionReq( spot, posReqId ) { this.$emit("setPositionReq", this.getActiveSpots(spot), posReqId); },
     setPrice( spot, price)
     {
        //console.log("SETPRICE("+price+")");
     	this.$emit("setPrice", this.getActiveSpots(spot), price);
     },
     getActiveSpots(spot) {
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
     		for ( var i in this.spots )
	     	{
	     		let spot = this.spots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	     }
	     return mySpots;
     },
     changeType(event, type, spot)
     {
        try {
        this.$refs.menu.close();
        } catch (e) { 
        //
        } 
     	var mySpots = new Array();
     	if ( this.marked.length == 0 && spot )
     	{
     		mySpots.push(spot);
     	}
     	else
     	{
	     	for ( var i in this.filteredSpots )
	     	{
	     		let spot = this.filteredSpots[i];
	     		for ( var s in this.marked )
	     		{
	     			if (this.marked[s] === spot.id)
	     			{
	     				mySpots.push(spot);
	     				break;
	     			}
	     		}
	     	}
	    }
     	this.$emit("setTypeForSpots", mySpots, type);
     	
     },
     getMarked(spot) 
     { 
     	if (spot && this.marked.includes(spot.id)) 
     	{
     		return "background-color: #888; color: #fff;"
     	} 
     	 
     	return "background-color: "+sessionStorage.bgColor+";";
     }, 
     mark(spot, idx)
     {
        var add = true;
     	for ( var s in this.marked )
     	{
     		if (this.marked[s] === spot.id)
     		{
     			this.marked.splice( s, 1);
     			
     			if ( spot.mainSpotId && this.marked.includes(spot.mainSpotId))
	     		{
	     			for ( s in this.marked )
			     	{
			     		if (this.marked[s] === spot.mainSpotId)
			     		{
			     			this.marked.splice( s, 1);
			     		}
			     	}
	     		}
	     		var arr = this.spots.filter(s => s.mainSpotId == spot.id);
	     		for ( var i in arr )
	     		{
	     			let reminder = arr[i];
	     			if ( this.marked.includes( reminder.id))
		     		{
		     			for ( s in this.marked )
				     	{
				     		if (this.marked[s] === reminder.id)
				     		{
				     			this.marked.splice( s, 1);
				     		}
				     	}
		     		}
	     		}
	     		if ( spot.mainSpotId )
	     		{
		     		arr = this.spots.filter(s => s.mainSpotId == spot.mainSpotId);
		     		for ( i in arr )
		     		{
		     			let reminder = arr[i];
		     			if ( this.marked.includes( reminder.id))
			     		{
			     			for ( s in this.marked )
					     	{
					     		if (this.marked[s] === reminder.id)
					     		{
					     			this.marked.splice( s, 1);
					     		}
					     	}
			     		}
		     		}
		     	}
     			add = false;
     			break;
     		}
     	}
     	if ( add )
     	{
     		this.marked.push( spot.id);
     		if ( spot.mainSpotId && !this.marked.includes(spot.mainSpotId))
     		{
     			this.marked.push( spot.mainSpotId);
     		}
     		arr = this.spots.filter(s => s.mainSpotId === spot.id);
     		for ( i in arr )
     		{
     			let reminder = arr[i];
     			if ( !this.marked.includes( reminder.id))
	     		{
	     			this.marked.push( reminder.id);
	     		}
     		}
     		if ( spot.mainSpotId )
     		{
	     		arr = this.spots.filter(s => s.mainSpotId === spot.mainSpotId);
	     		for ( i in arr )
	     		{
	     			let reminder = arr[i];
	     			if ( !this.marked.includes( reminder.id))
		     		{
		     			this.marked.push( reminder.id);
		     		}
	     		}
	     	}
     	}
     	//this.$forceUpdate();
     },
     markAll(event)
     {
        var idArr = new Array();
        for ( var i in this.spots)
        {
        	idArr.push(this.spots[i].id);
        }
     	this.marked = idArr;
     	this.$forceUpdate();
     },
     runInBreakOptimizer(spot)
     {
    	let breakKey =  spot.mediaId+"/"+spot.date+"/"+spot.gridId+"/"+(spot.dayGridId?spot.dayGridId:0)+"/"+spot.breakNo+"/"+spot.breakTypeId;
       
    	this.$emit("runIBO", breakKey);
     },
     toggleMarks()
     {
        var idArr = new Array();
        for ( var i in this.spots)
        {
        	idArr.push(this.spots[i].id);
        }
     	this.marked =  idArr.filter(id => !this.marked.includes(id));
     },
     checkMark()
     {
        var idArr = new Array();
        for ( var i in this.filteredSpots)
        {
        	idArr.push(this.filteredSpots[i].id);
        }
     	this.marked =  this.marked.filter(id => idArr.includes(id));
     },
     getBreakDuration(spots)
     {
     	var dur = 0;
     	for ( var s in spots )
     	{
     		dur += spots[s].duration;
     	}
     	return dur;
     },
     
     print(spot, col)
     {
        //return spot[ col.show ];
     	let val = spot[ col.show ];
     	if ( col.string )
     	{
     		return val ? val : "";
     	}
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	if ( col.weekday )
     	{
     		return this.weekdayArray[ ((spot.weekday+1)%7)+1 ];
     	}
     	if ( col.time )
     	{
     		return this.printTime(val, ":", false, false);
     	}
     	return formatNumber( val );
     },
     
     doFilterSpots()
     {
     	//this.filteredSpots = this.spots;
     	//this.doOrderBy();
     	//return this.filteredSpots;
     },
     doOrderBy(spots2Show)
     {
        let that = this;
        let clone = [...spots2Show]
     	clone.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( v1 > v2 )
	     		{
	     		   
	     			return iCol.sorting;
	     		}
	     		if ( v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return 0;
	     });
	     return clone;
     },
     getOrderBy( col )
     {
     	for ( var i in this.orderBy)
     	{
     		let iCol = this.orderBy[i];
     		if ( iCol.show === col.show )
     		{
     		    if ( iCol.sorting > 0 )
     		    {
     				return "A"+(parseInt(i)+1);
     			}
     			return "D"+(parseInt(i)+1);
     		}
     	}
     	return "";
     },
     setOrderBy( col )
     {
        if ( this.identifier==='Clipboard')
        {
	        let add = true;
	        for ( let sIdx in this.spots2Show )
	     	{
	        	let spot = this.spots2Show [sIdx];
	        	
	     	}
	     	for ( let i in this.orderBy)
	     	{
	     		let iCol = this.orderBy[i];
	     		if ( iCol.show === col.show )
	     		{
	     		    if ( col.sorting === 1 )
	     		    {
	     		    	col.sorting = -1;
	     		    }
	     		    else
	     		    {
	     				this.orderBy.splice( i, 1);
	     				col.sorting = 0;
	     			}
	     			add = false;
	     			break;
	     		}
	     	}
	     	if ( add )
	     	{
	     		this.orderBy.push( col );
	     		col.sorting = 1;
	     	}
	     	this.spots2Show = this.doOrderBy(this.spots2Show);
	     	this.$forceUpdate();
	     }
     },
     getColumnStyle(col, spot)
     {
        return "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%"+";"+this.getMarked(spot);
     },
     getAdSpaceSection( no )
     {
     	if ( no === 0 ) { return "Section BEFORE" }
     	else if ( no === 999 ) { return "Section AFTER" }
     	else { return "Inside-Section "+no }
     },
     
     calculatePrice(dur)
     {
     	let base = this.gridAds.rateCard.price/this.gridAds.rateCard.duration;
     	var returnValue = 0;
     	for (var pi in this.gridAds.priceIndexes )
     	{
     		let index = this.gridAds.priceIndexes[pi];
     		if ( index.durFrom <= dur && index.durTo >= dur )
     		{
     			returnValue = index.index/100*base*(dur*index.factor+index.value);
     			break;
     		}
     	} 
     	if ( this.gridAds.priceIndex.rounding )
     	{
     		return Number(Math.round(returnValue)).toLocaleString(myLocale())
     	}
       return Number(Math.round(returnValue*100)/100).toLocaleString(myLocale())
     },
     
     timezoneStyle() {
        if ( this.hasTimezone() )
        {
    		return "float: left; width: 10%  !important; background-color: #eee; color: #444;";
    	}
    	return "float: left; width: 0pt !important; background-color: #eee; color: transparent;";
    },

      mainTime(time) { 
        let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        if ( hh >= 24 )
        {
        	return "height: "+this.zoom+"pt; background-color: #e0e0ff;";
        }
      	return "height: "+this.zoom+"pt;";
      },
      negative(time)
      {
        return ( time < 0 )
      },
      printTime(time,sep,withSec,noHour)
      {
        return printTimeOpt(time,sep,withSec,noHour)
       },
      getBreakCode(adSpace, brk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "OP";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "END";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += "."+brk.shortname;
        code += "@";
      	if ( this.gridAds.category )
      	{
      		code += this.gridAds.category.shortname;
      	}
      	code += this.printTime(this.gridAds.starttime,"");
      	return code;
      },
    printTime2(time)
    {
        printTime2(time);    
    },
    
    startDrag (evt, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('spotIndex', idx)
  	},
  	startDragColumn (evt, col, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
  	startDragBreak( evt, source, what ) {
  		evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what
        evt.dataTransfer.setData('dndType', what)
        evt.dataTransfer.setData('breakSource', source)
        evt.dataTransfer.setData('dayId', this.dayId)
       
  	},
  	startDragSpot (evt, spot, idx, source, what) {
  		//console.log( "WHAT(startDragSpot)---->" + what);
  		evt.dataTransfer.setData('dndType', what);
        evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what;
        
        var mySpots = new Array();
        evt.dataTransfer.setData('spotSource', source)
        evt.dataTransfer.setData('dayId', this.dayId)
     	if ( !this.marked.length && spot )
     	{
             mySpots.push(spot);
             evt.dataTransfer.setData('spotIndex', idx)
             evt.dataTransfer.setData('spot', JSON.stringify(spot))
             return;
     	}
     	else
     	{
     		mySpots =  this.spots.filter( s => this.marked.includes( s.id))
	    }
	    
	    //console.log( "WHAT---->" + what);
        evt.dataTransfer.setData('spots', JSON.stringify(mySpots))
    },
    onDropSpot (evt, target, breakIdentifier, moveType) 
     {
        const breakSource = evt.dataTransfer.getData('breakSource');
        const fromDay = evt.dataTransfer.getData('dayId');
        const dndType = evt.dataTransfer.getData('dndType');
        if ( ! moveType )
        {
        	moveType = dndType;
        }
        //alert( "WHAT---->" + dndType);
        if ( breakSource )
        {
            //alert(dndType+"Break"+","+ breakIdentifier+","+breakSource+","+fromDay+","+this.dayId)
            this.$emit( moveType+"Break", breakIdentifier, breakSource, fromDay, this.dayId);
        	return;
        }
        const spotSource = evt.dataTransfer.getData('spotSource');
        const source = evt.dataTransfer.getData('spotIndex');
		
        if ( evt.dataTransfer.getData('spots'))
        {
        	let movingSpots = JSON.parse(evt.dataTransfer.getData('spots'));
        	//console.log( "WHAT (mv)---->" + moveType);
        	this.$emit( "moveSpots", moveType, movingSpots, breakIdentifier, fromDay, this.dayId);
        }
        else if ( source && evt.dataTransfer.getData('spot'))
        {
            let movingSpot = JSON.parse(evt.dataTransfer.getData('spot'));
            let fn = "arrangeSpots";
			//alert( spotSource+ "/"+ movingSpot.breakIdentifier +" vs. "+ breakIdentifier )
            if ( spotSource != breakIdentifier )
            {
                fn = "moveSpots";
            }
            if ( fn == "arrangeSpots")
            {
                let move = this.spots[ source];
                if ( target < source )
                {
                	this.spots.splice(source, 1);
                	this.spots.splice(target, 0, move);
                }
                
                
                if ( target > source )
                {
                	this.spots.splice(target+1, 0, move);
                	this.spots.splice(source, 1);
                }
                //this.spots[ target] = this.spots[ source];
                //this.spots[ source] = replace;
                for ( var p in this.spots)
                {
                    this.spots[p].position = parseInt(p)+1;
                }
            	if ( breakIdentifier != 'Clipboard' )
            	{
                	this.$emit( fn, this.spots);
            	}
            }
            else
            {
            	//alert( fn + ";"+ breakIdentifier+" "+JSON.stringify(movingSpot));
            	//console.log( "WHAT("+fn+")---->" + moveType);
				//alert( "moveType---->" + moveType+ "/"+fn);
                this.$emit( fn, moveType, [movingSpot], breakIdentifier, spotSource, fromDay, this.dayId);
                
            }
        }
		return;
	},
  	deleteSpot(spot)
  	{
  	   this.$emit("deleteSpot", [spot.id], this.identifier);
  	},

	
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
	     	var saveUser = 0;
	     	if ( this.identifier === 'Clipboard')
	    	{
	    		if ( this.user && ! this.user.spotListColumnsCB)
		     	{
		     		saveUser = 1;
		     		this.user.spotListColumnsCB=columns;
		     	}
		     	if ( this.user && this.user.spotListColumnsCB != columns)
		     	{
		     		saveUser = 1;
		     		this.user.spotListColumnsCB=columns;
		     	}
	    	}
	    	else
	    	{
		     	if ( this.user && ! this.user.spotListColumnsRD)
		     	{
		     		saveUser = 1;
		     		this.user.spotListColumnsRD=columns;
		     	}
		     	if ( this.user && this.user.spotListColumnsRD != columns)
		     	{
		     		saveUser = 1;
		     		this.user.spotListColumnsRD=columns;
		     	}
		     }
	     	//alert( saveUser);
	     	if ( saveUser ) 
	     	{
	     		 let that = this;
	     		
	     	    that.$emit("userUpdated", that.user);
	     	   
			}
		}
     	this.widthCalculated = wTot;
	},
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //console.log(this.selectedIN)
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	        } catch (e)
	        {
	        	this.selected = [];
	        }
	    	
	    }
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = this.selectedDefault;
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( var i in this.selected )
		{
			let colName = this.selected[i];
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.name == colName )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	updateSpots( updated)
    {	
    	let ids = new Array();
    	for ( var x in updated )
    	{
    		ids.push( updated[x].id);
    	}
    	
    	for ( var s in this.spots )
        {
        	let spot = this.spots[s];
        	if ( ids.includes(spot.id) )
        	{
        	    let repl = updated.find( x => x.id == spot.id);

        		Object.assign( this.spots[s], repl);
        	}
        	
        }
        //this.doFilterSpots();
	    //this.doOrderBy();
    },
	addColumn (show) {
		if ( this.avColumnsVisible )
		{
			var colToInsert;
			for ( var i in this.availableColumns )
	     	{
	     		let col = this.availableColumns[ i];
	     		if ( col.show == show )
	     		{
	     			colToInsert = col;
	     			break;
	     		}
	     	}
	     	this.selectedColumns.splice( this.selectedColumns.length-1, 0, colToInsert); // insert at 6
	     	
			this.calcWidths( true);
		}
	}
  },
  watch: {
	spots: function() {
	    //console.log( "watch spots**")
        this.arrangeColumns();
        this.spots2Show = this.doOrderBy(this.spots);
        this.$forceUpdate();
        //this.doFilterSpots();
        
        
		
    },
	update: function() {
	   this.$forceUpdate();
	},
    selectedIN: function() {
        
    	this.arrangeColumns();
    },
    updatedSpots: function() { this.updateSpots( this.updatedSpots); },
    user: function() {
        
    	this.arrangeColumns();
    }
  },  
  created() {
	 if ( this.spots ) {
	 	//console.log( "CREATED**")
	 	//console.log( this.spots.length)
	 	this.arrangeColumns();
	 	//console.log( this.spots.length)
	 	this.spots2Show = this.doOrderBy(this.spots);
	    //console.log( this.spots.length)
		this.$forceUpdate();
	 } 
  },
  beforeUpdate() {
  	
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #bbf; 
}
.headerEmpty { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #888; 
  
}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 14pt !important; 
  font-weight: bold; 
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  //background-color: #f8f8f8 !important; 
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.spotLine
{
	cursor: pointer;
}
.reqLineBG { 
  background-color: #ccc !important;
  color: #444 !important; 
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	//background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.todrag {
 display: flex;
 width: 100%; 
 //margin-bottom: 2pt;
 //height: 15pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    //background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 
.modal-mask {
  //position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {

  height: 100%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  //background-color: #fff;
  border-radius: 2px;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  height: 100%;
}
.displayRed {
	color: #000;
	background-color: #fcc;
	border-bottom: 1pt double red;
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.gridMainBV { position: absolute; z-Index: 2; text-align: center; width: 99% ; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
.adMainBV { 
  float: top;
  position: absolute; 
  align-items: center;
  justify-content: center;
  z-Index: 17; 

  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 
  border: .5pt solid black;
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
}
.adMainInnerRight { 
  color: #000; 
  width: 100%;
  text-align: left !important;
  //border-bottom: .5pt solid #ddd;
  padding-left: 5pt;
  //background-color: rgba(255, 255, 255, .6);
  //border-left: .5pt solid #ddd;
    opacity: 1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.adMainInnerLeft { 
  align-items: center;
  justify-content: center;
  float: left top;
  z-Index: 17; 
  padding-top: 2pt;
  font-size: 9pt;
  //background-color: rgba(255, 255, 255, .6);
  font-weight: bold;
  margin-left: 2pt; 
  margin-right: 4pt; 
  color: #666; 
  height: 100% !important;
  
    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
</style>
