var gi_data = [];

export function store( obj )
{
	gi_data = obj;
} 


export function restore( )
{
	return gi_data;
} 